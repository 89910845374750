import React, { FC, useContext, useRef } from "react";
import { graphql } from "gatsby";
import { WavySection } from "../components/wavy-section";
import {
  colors,
  ySpaceRem,
  xSpaceRem,
  fontStacks,
  targetBaseFontSizePx,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center } from "../components/center";
import { PageTemplateQuery, BlockFragment } from "../../graphql-types";
import { Footer } from "../components/footer";
import { css } from "styled-components";
import { Blocks } from "../components/blocks";
import { SEO } from "../components/seo";

export const query = graphql`
  query PageTemplate($id: ID!) {
    wp {
      page(id: $id) {
        title
        blocks {
          ...Block
        }
        seo {
          ...SEO
        }
      }
    }
  }
`;

const PageTemplate: FC<{ data: PageTemplateQuery }> = props => {
  const { remInPx, waveClearance } = useContext(LayoutContext);
  const pageTitleRef = useRef<HTMLHeadingElement>(null);

  return (
    <>
      <SEO {...props.data.wp.page?.seo} />

      <WavySection
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
          paddingBottom: `${ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <div
            style={{
              ...(pageTitleRef.current &&
                (() => {
                  const paddingYRem =
                    ySpaceRem / 2 -
                    ((pageTitleRef.current.getBoundingClientRect().height /
                      remInPx) %
                      (ySpaceRem / 2));

                  return {
                    paddingTop: `${0.5 * paddingYRem}rem`,
                    paddingBottom: `${0.5 * paddingYRem}rem`,
                  };
                })()),
              transition: "padding 0.25s ease",
            }}
          >
            <h1
              ref={pageTitleRef}
              style={{
                fontFamily: fontStacks.cubano,
              }}
              css={(() => {
                const minFontSizeRem = 2;
                const maxFontSizeRem = 2.8888;
                const vwRangeStartPx = 500;

                return css`
                  font-size: ${(minFontSizeRem *
                    (remInPx || targetBaseFontSizePx)) /
                    (vwRangeStartPx / 100)}vw;
                  line-height: 1;

                  @media (max-width: ${vwRangeStartPx}px) {
                    font-size: ${minFontSizeRem}rem;
                  }

                  @media (min-width: ${vwRangeStartPx *
                      (maxFontSizeRem / minFontSizeRem)}px) {
                    font-size: ${maxFontSizeRem}rem;
                  }
                `;
              })()}
            >
              {props.data.wp.page?.title}
            </h1>
          </div>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `calc(${1.5 * ySpaceRem}rem + ${waveClearance})`,
        }}
      >
        <Center>
          <div style={{ maxWidth: `${32 * xSpaceRem}rem` }}>
            <Blocks blocks={props.data.wp.page?.blocks as BlockFragment[]} />
          </div>
        </Center>
      </div>

      <Footer backgroundColor={colors.lightestGrey} />
    </>
  );
};

export default PageTemplate;
